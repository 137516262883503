import { DialogueTitle } from "@imago-cloud/design-system";
const DialogTitle = ({
  children,

  onClose,
  isClose = true,
}: {
  children?: React.ReactNode;
  onClose?: () => void;
  isClose?: boolean;
}) => {
  return (
    <DialogueTitle
      isClose={isClose}
      onClose={onClose}
      sx={{
        width: "100%",
        height: "68px",
        padding: "22px 20px 14px 28px",
      }}
    >
      {children}
    </DialogueTitle>
  );
};
export default DialogTitle;
