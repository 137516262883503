import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import "./i18n";
import { ThemeProvider } from "./Provider/ThemeProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import FormProvider from "./Core/Provider/FormProvider";
import MessageProvider from "./Core/Provider/MessageProvider";
import { ErrorBoundary } from "react-error-boundary";
import { UnknownErrorPage } from "./Pages/Error/UnknownErrorPage";
import { ENVIRONMENT } from "./config";
import packageJson from "../package.json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
console.debug(
  `#EXPORT_MODULE_CLIENT: ${ENVIRONMENT.toUpperCase()} v${packageJson.version}`
);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <ErrorBoundary FallbackComponent={UnknownErrorPage}>
        <RecoilRoot>
          <RecoilNexus />
          <QueryClientProvider client={new QueryClient()}>
            <BrowserRouter>
              <MessageProvider>
                <FormProvider>
                  <App />
                </FormProvider>
              </MessageProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);
