import { useQuery } from "@tanstack/react-query";

import { IProductItem } from "../../../../Types";
import { useMessage } from "../../../../Core/Provider/MessageProvider";
import { axios } from "../../../../Core/Axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import { EXPORT_PRODUCTS_API } from "../../../../Constants/api";
import { AxiosError } from "axios";
import { useErrorBoundary } from "react-error-boundary";

export interface IExportProductsResponse {
  formControlOptionList: string[];
  designCaseList: {
    designCaseId: string;
    designCaseName: string;
    solutionType: string;
    fileList: {
      groupId: string;
      groupKey: string | null;
      groupLabel: IProductItem["itemLabel"];
      itemList: IProductItem[];
    }[];
  }[];
}

const fetchList = async (designCaseIds?: string[]) =>
  await axios
    .post<{
      success: boolean;
      errorCode?: string;
      data: IExportProductsResponse;
    }>(EXPORT_PRODUCTS_API, {
      designCaseIds: designCaseIds,
    })
    .then(({ data: response }) => {
      const { data, success, errorCode } = response || {};
      if (!success) throw Error(errorCode);
      else return { ...data };
    });

export const useListQuery = () => {
  const {
    message: { designCaseIds, exactHost },
  } = useMessage();
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  return useQuery(
    ["list-query", designCaseIds],
    async () => {
      try {
        return (await fetchList(designCaseIds)) || [];
      } catch (error) {
        const { message } = error as unknown as AxiosError;
        if (message === "PAYMENT_QUEUE:PAYMENT_QUEUE_DESIGN_CASE_NOT_FOUND") {
          navigate("/cannot-be-found");
        } else {
          showBoundary(error);
        }
      }
    },

    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 100000,
      retry: 5,
      retryDelay: 1000,
      enabled:
        !!designCaseIds &&
        designCaseIds?.length > 0 &&
        exactHost !== "importTo",
    }
  );
};
