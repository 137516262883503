import { useRecoilState } from "recoil";
import {
  IProgressToastAtom,
  progressToastAtom,
  progressToastAtom_initialState,
} from "../Atoms/progressToastAtom";

const useProgressToast = () => {
  const [recoilState, setRecoilState] = useRecoilState(progressToastAtom);
  const onProgressToastChange = ({
    type,
    status,
    data,
  }: {
    type: IProgressToastAtom["type"];
    status: IProgressToastAtom["status"];
    data?: any;
  }) => {
    setRecoilState((prev) => ({ ...prev, open: true, type, status, data }));
  };
  const onProgressToastReset = () => {
    setRecoilState(progressToastAtom_initialState);
  };
  return { recoilState, onProgressToastChange, onProgressToastReset };
};

export default useProgressToast;
