import { useCallback } from "react";
import { useListQuery } from "../../Modules/ExportList/Hooks/ReactQueries/useListQuery";

const useList = () => {
  const listQuery = useListQuery();
  const { data: queryData } = listQuery;
  const { designCaseList = [] } = queryData || {};

  const getLists = useCallback(() => designCaseList, [designCaseList]);

  const getAllLists = useCallback(
    () => designCaseList.map(({ fileList }) => fileList).flat(2),
    [designCaseList]
  );
  const getAllListsItems = useCallback(
    () =>
      getAllLists()
        .map(({ itemList }) => itemList)
        .flat(2),
    [getAllLists]
  );
  const getAllListsItemsIds = useCallback(
    () => getAllListsItems().map(({ itemId }) => itemId),
    [getAllListsItems]
  );

  const getDefaultItemIds = useCallback(
    (type: "single" | "multiple") => {
      switch (type) {
        case "single":
          return getAllListsItems()
            .filter(({ isInitiallyChecked }) => isInitiallyChecked)
            .map(({ itemId }) => itemId);

        case "multiple":
          return getAllListsItemsIds();
      }
    },
    [getAllListsItems, getAllListsItemsIds]
  );
  return {
    listQuery,
    getLists,
    getAllLists,
    getAllListsItems,
    getAllListsItemsIds,
    getDefaultItemIds,
  };
};

export default useList;
