import axios, { AxiosInstance } from "axios";

export const createAxiosInstance = ({
  baseURL,
  requestAccessToken,
}: {
  baseURL: string;
  requestAccessToken: () => Promise<
    | {
        accessToken: string;
      }
    | null
    | undefined
  >;
}): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  let accessToken: string | null = null;

  const setAuthorizationHeader = (token: string) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  instance.interceptors.request.use(
    async (config) => {
      if (!accessToken) {
        const response = await requestAccessToken();
        const { accessToken: newAccessToken } = response || {};
        if (newAccessToken) {
          accessToken = newAccessToken;
          setAuthorizationHeader(accessToken);
        }
      }
      config.headers = config.headers || {};
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;

      if (error.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        const response = await requestAccessToken();
        const { accessToken: newAccessToken } = response || {};
        if (newAccessToken) {
          accessToken = newAccessToken;
          setAuthorizationHeader(accessToken);
          originalConfig.headers["Authorization"] = `Bearer ${accessToken}`;
          return instance(originalConfig);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
