import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../../Core/Axios/axiosInstance";
import { CREDITS_BALANCE_API } from "../../../../Constants/api";

export type CreditBalanceQueryResponseType = {
  success: boolean;
  data: {
    balance: number;
    paidCredits: number;
    freeCredits: number;
    tenantId: string;
  };
};

export const useCreditBalanceQuery = () => {
  return useQuery(
    ["credit-balance-query"],
    () =>
      axios
        .get<CreditBalanceQueryResponseType>(CREDITS_BALANCE_API)
        .then(({ data: res }) => res.data),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime: 10000,
      cacheTime: 10000,
    }
  );
};
