import { Cicon, Typography } from "@imago-cloud/design-system";

import { Stack } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogPaper,
  DialogTitle,
} from "../../Core/Layouts/Dialog";
import { onCloseApp } from "../../Core/Utils/postMessage";
import i18next from "i18next";

export const UnknownErrorPage = () => {
  return (
    <Dialog>
      <DialogPaper sx={{ width: "880px", height: "640px" }}>
        <DialogTitle onClose={onCloseApp} isClose />
        <DialogContent>
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              paddingTop: "209px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                width: "600px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Cicon
                icon="Warning"
                svgProps={{ width: "36px", height: "36px" }}
              />
              <Typography variant="H24">
                {i18next.t("unknown_error_dialog.title")}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </DialogPaper>
    </Dialog>
  );
};
