import { RouteObject, useRoutes } from "react-router-dom";

import { Suspense, lazy } from "react";
import { UnknownErrorPage } from "../Pages/Error/UnknownErrorPage";
import { CannotBeFoundPage } from "../Pages/Error/CannotBeFoundPage";

const MainPage = lazy(() => import("../Pages/Main"));
const CamSWPage = lazy(() => import("../Pages/CamSW"));
const ImportToPage = lazy(() => import("../Pages/ImportTo"));
export const routePath: RouteObject[] = [
  {
    path: "/",
    element: (
      <Suspense fallback={<div />}>
        <MainPage />
      </Suspense>
    ),
    errorElement: <div />,
  },
  {
    path: "/cam-sw",
    element: (
      <Suspense fallback={<div />}>
        <CamSWPage />
      </Suspense>
    ),
  },
  {
    path: "/import-to",
    element: (
      <Suspense fallback={<div />}>
        <ImportToPage />
      </Suspense>
    ),
  },
  {
    path: "/unknown-error",
    element: <UnknownErrorPage />,
  },
  {
    path: "/cannot-be-found",
    element: <CannotBeFoundPage />,
  },
  {
    path: "*",
    element: <UnknownErrorPage />,
  },
];

export default function Routes() {
  const routes = useRoutes(routePath);
  return routes;
}
