import { atom } from "recoil";

type IAsyncConfirmDialogAtom = {
  open: boolean;
  component: React.ReactNode | null;
  resolve: (value: boolean) => void;
};
export const asyncConfirmDialogAtominitialState: IAsyncConfirmDialogAtom = {
  open: false,
  component: null,
  resolve: () => {},
};
export const asyncConfirmDialogAtom = atom<IAsyncConfirmDialogAtom>({
  key: "asyncConfirmDialogAtom",
  default: asyncConfirmDialogAtominitialState,
});
