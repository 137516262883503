import { Dialogue } from "@imago-cloud/design-system";

const Dialog = ({
  open = true,
  children,
}: {
  open?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Dialogue
      open={open}
      PaperProps={{
        sx: {
          zIndex: "88888",
          width: "fit-content !important",
          maxWidth: "fit-content !important",
          height: "auto !important",
          maxHeight: "auto !important",
          margin: "0px",
        },
      }}
      disableBackdropClick
    >
      {children}
    </Dialogue>
  );
};
export default Dialog;
