import { DialogueContent } from "@imago-cloud/design-system";
const DialogContent = ({
  children,
  sx,
}: {
  children?: React.ReactNode;
  sx?: React.CSSProperties;
}) => {
  return (
    <DialogueContent
      sx={{
        padding: "0px",
        width: "100%",
        height: "100%",
        ...sx,
      }}
    >
      {children}
    </DialogueContent>
  );
};

export default DialogContent;
