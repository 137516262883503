import { useRecoilState } from "recoil";
import { asyncConfirmDialogAtom } from "./asyncConfirmDialogAtom";

import React from "react";

const AsyncConfirmDialogProvider = () => {
  const [dialog, setDialog] = useRecoilState(asyncConfirmDialogAtom);

  const handleClose = (result: any) => {
    setDialog((prev) => ({ ...prev, open: false }));
    dialog.resolve(result);
  };
  return (
    <>
      {dialog.open &&
        dialog.component &&
        React.isValidElement(dialog.component) &&
        React.cloneElement(dialog.component as React.ReactElement<any>, {
          onNext: handleClose,
        })}
    </>
  );
};

export default AsyncConfirmDialogProvider;
