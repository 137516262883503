import { theme } from "@imago-cloud/design-system";
import { Stack } from "@mui/material";

const Toast = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        position: "fixed",
        zIndex: 9999,
        top: "0px",
        left: "0px",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(2px)",
      }}
    >
      <Stack
        sx={{
          width: "433px",
          borderRadius: "10px",
          backgroundColor: theme.palette.grey[900],
          padding: "16px 20px",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default Toast;
