import { Stack } from "@mui/material";

const DIALOG_DEFAULT_SIZE = {
  width: "880px",
  height: "640px",
};

const DialogPaper = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: React.CSSProperties;
}) => {
  return (
    <Stack
      sx={{
        width: `${DIALOG_DEFAULT_SIZE.width} !important`,
        maxWidth: DIALOG_DEFAULT_SIZE.width,
        // height: DIALOG_DEFAULT_SIZE.height,
        // maxHeight: `${DIALOG_DEFAULT_SIZE.height} !important`,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};
export default DialogPaper;
