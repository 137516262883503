import PaymentToast from "../PaymentToast";
import ExportToast from "../ExportToast";
import useProgressToast from "../Hooks";
import CamHealthCheckToast from "../CamHealthCheckToast";

const ToastProvider = () => {
  const {
    recoilState: { open, type, status, data },
  } = useProgressToast();

  if (!open) return null;
  return (
    <>
      {(() => {
        switch (type) {
          case "payment":
            return <PaymentToast status={status} />;
          case "export":
            return <ExportToast status={status} data={data} />;
          case "cam_health_check":
            return <CamHealthCheckToast status={status} />;

          default:
            return null;
        }
      })()}
    </>
  );
};

export default ToastProvider;
