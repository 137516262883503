export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? "";
export const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN ?? "";

export const CLOUD_URL = process.env.REACT_APP_CLOUD_URL ?? "";
export const CROWN_URL = process.env.REACT_APP_CROWN_URL ?? "";
export const MODELER_URL = process.env.REACT_APP_MODELER_URL ?? "";
export const MILLING_URL = process.env.REACT_APP_MILLING_URL ?? "";

export const EXPLORER_MODULE_URL =
  process.env.REACT_APP_EXPLORER_MODULE_URL ?? "";
export const VIEWER_MODULE_URL = process.env.REACT_APP_VIEWER_MODULE_URL ?? "";
export const MODULE_TESTBED_URL =
  process.env.REACT_APP_MODULE_TESTBED_URL ?? "";

export const BATCH_DEV_URL = process.env.REACT_APP_BATCH_DEV_URL ?? "";
export const BATCH_BUILD_PROTOCOL =
  process.env.REACT_APP_BATCH_BUILD_PROTOCOL ?? "";
export const BATCH_URL = process.env.REACT_APP_BATCH_URL ?? "";

export const TERMS_URL =
  "https://imagoworks.gitbook.io/terms-and-conditions/#.-payment-terms";

export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL ?? "";

export const DENTBIRD_LINKER_DOWNLOAD_URL =
  process.env.REACT_APP_DENTBIRD_LINKER_DOWNLOAD_URL ?? "";
