import { useEffect, useState } from "react";
const LoadingDots = ({
  maxDots = 3,
  interval = 300,
}: {
  maxDots?: number;
  interval?: number;
}) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDots((prev) => (prev.length < maxDots ? prev + "." : ""));
    }, interval);

    return () => clearInterval(dotInterval);
  }, [maxDots, interval]);

  return <>{dots}</>;
};
export default LoadingDots;
