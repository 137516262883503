import { atom } from "recoil";

export interface IProgressToastAtom {
  open: boolean;
  type: "payment" | "export" | "cam_health_check" | null;
  status: "progress" | "done" | "failed" | null;
  data: {};
}

export const progressToastAtom_initialState: IProgressToastAtom = {
  open: false,
  type: null,
  status: null,
  data: {},
};
export const progressToastAtom = atom<IProgressToastAtom>({
  key: "progressToastAtom",
  default: progressToastAtom_initialState,
});
