import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { IMessageData } from "../../../Types";
import { useNavigate } from "react-router-dom";

interface MessageContextType {
  message: IMessageData;
  isMessageReceived: boolean;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [isMessageReceived, setIsMessageReceived] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState<IMessageData>({
    designCaseIds: [],
    exactHost: undefined,
    importToId: "",
  });

  const getIsValidMessageData = (search: string[], target: any) =>
    target && typeof target === "object" && search.some((key) => key in target);

  useEffect(() => {
    if (isMessageReceived) return;
    const handleMessage = ({ data }: MessageEvent) => {
      const { designCaseIds, exactHost, importToId } = data || {};
      if (
        getIsValidMessageData(
          ["designCaseIds", "exactHost", "importToId"],
          data
        )
      ) {
        setMessage({ designCaseIds, exactHost, importToId });
        setIsMessageReceived(true);

        if (exactHost === "importTo") {
          if (!importToId) throw Error("importToId is empty");
          navigate("/import-to");
        } else {
          if (designCaseIds?.length === 0)
            throw Error("designCaseIds not found");
        }
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [isMessageReceived, navigate]);

  return (
    <MessageContext.Provider value={{ message, isMessageReceived }}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;

export const useMessage = (): MessageContextType => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error("useMessage must be used within a MessageProvider");
  }
  return context;
};
