import { DialogueActions } from "@imago-cloud/design-system";

const DialogActions = ({
  children,
  sx,
}: {
  children?: React.ReactNode;
  sx?: React.CSSProperties;
}) => {
  return (
    <DialogueActions sx={{ padding: "16px", height: "68px", ...sx }}>
      {children}
    </DialogueActions>
  );
};

export default DialogActions;
