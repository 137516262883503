import { FormProvider as OFormProvider, useForm } from "react-hook-form";

import { useEffect } from "react";
import { useMessage } from "../MessageProvider";
import useList from "../../../Hooks/Core/useList";
import { useSettingQuery } from "../../../Hooks/ReactQueries/useSettingQuery";

const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const { isMessageReceived, message } = useMessage();

  const {
    listQuery: { data: listData, isLoading: isListLoading },
    getDefaultItemIds,
  } = useList();

  const { data: settingData, isLoading: isUserLoading } = useSettingQuery();

  const methods = useForm();
  const { reset } = methods || {};

  useEffect(() => {
    if (!isListLoading && !isUserLoading && isMessageReceived && listData) {
      const isMultipleCases = message?.designCaseIds?.length > 1;
      const { exportTo } = settingData || {};
      const { isAxisAccordingToCAM, fileFormat } = exportTo || {};
      reset({
        exportOptions: {
          isAxisAccordingToCAM,
          fileFormat,
        },
        itemIds: getDefaultItemIds(isMultipleCases ? "multiple" : "single"),
      });
    }
  }, [
    message,
    getDefaultItemIds,
    isListLoading,
    isMessageReceived,
    isUserLoading,
    reset,
    settingData,
    listData,
  ]);

  return <OFormProvider {...methods}>{children}</OFormProvider>;
};

export default FormProvider;
